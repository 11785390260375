console.log("test");

window.toggleMenu = function () {
    var element = document.getElementById("navbarNav");
    element.classList.toggle("show");
};

window.showMore = function () {
    var element = document.getElementById("images-row");
    element.classList.toggle("show");
};
